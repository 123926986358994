import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchResult } from '../models/search-result.model';
import { SearchQuery } from '../models/search-query.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExploreAndSearchResponse } from '../../../typings/api';
import { environment } from '@env';

@Injectable()
export class AutoCompleteSearchService {
    constructor(private httpClient: HttpClient) {}

    public autoCompleteSearch(query: string): Observable<SearchResult> {
        return this.httpClient
            .post<ExploreAndSearchResponse>(
                `${environment.GATE_URL_2}/search/old/v2`,
                new SearchQuery(query)
            )
            .pipe(
                map((result) => {
                    const searchResult: SearchResult = {
                        site: result.site.data,
                        touring_area: result.touring_area.data,
                        trek: result.trek.data,
                        user: result.user.data
                    };

                    return this.resultToShow(searchResult);
                })
            );
    }

    private resultToShow(searchResult: SearchResult): SearchResult {
        const DESIRED_AMOUNT_PER_TYPE = 2;
        const MAX_TOTAL_AMOUNT = 6;

        const siteAmount = Math.min(
            searchResult.site.length,
            DESIRED_AMOUNT_PER_TYPE
        );
        const areasAmount = Math.min(
            searchResult.touring_area.length,
            DESIRED_AMOUNT_PER_TYPE
        );
        const usersAmount = Math.min(
            searchResult.user.length,
            DESIRED_AMOUNT_PER_TYPE
        );

        let treksLimit;
        let usersLimit;
        let siteLimit;
        let areasLimit;

        if (siteAmount >= 2) {
            treksLimit = Math.min(
                searchResult.trek.length,
                MAX_TOTAL_AMOUNT - siteAmount - usersAmount
            );
            siteLimit = Math.min(
                searchResult.site.length,
                MAX_TOTAL_AMOUNT - treksLimit - usersAmount
            );
            usersLimit = Math.min(
                searchResult.user.length,
                MAX_TOTAL_AMOUNT - treksLimit - siteLimit
            );
            areasLimit = 0;
        } else {
            if (siteAmount === 0) {
                treksLimit = Math.min(
                    searchResult.trek.length,
                    MAX_TOTAL_AMOUNT - areasAmount - usersAmount
                );
                areasLimit = Math.min(
                    searchResult.touring_area.length,
                    MAX_TOTAL_AMOUNT - treksLimit - usersAmount
                );
                usersLimit = Math.min(
                    searchResult.user.length,
                    MAX_TOTAL_AMOUNT - treksLimit - areasLimit
                );
                siteLimit = 0;
            } else {
                siteLimit = 1;
                areasLimit = 1;
                treksLimit = Math.min(
                    searchResult.trek.length,
                    MAX_TOTAL_AMOUNT - siteLimit - areasAmount - usersAmount
                );
                usersLimit = Math.min(
                    searchResult.user.length,
                    MAX_TOTAL_AMOUNT - treksLimit - siteLimit - areasLimit
                );
            }
        }

        searchResult.trek.splice(
            treksLimit,
            searchResult.trek.length - treksLimit
        );
        searchResult.site.splice(
            siteLimit,
            searchResult.site.length - siteLimit
        );
        searchResult.touring_area.splice(
            areasLimit,
            searchResult.touring_area.length - areasLimit
        );
        searchResult.user.splice(
            usersLimit,
            searchResult.user.length - usersLimit
        );

        return searchResult;
    }
}
