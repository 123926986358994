export class SearchQuery {
    query_filter: any;
    constructor(query: string) {
        this.query_filter = {
            filter: [new Filter(query)],
            limit: new Limit(),
            offset: new Offset()
        };
    }
}

class Filter {
    m_key = 'text';
    m_value: string;
    constructor(query: string) {
        this.m_value = query;
    }
}

class Limit {
    trek = 6;
    site = 6;
    touring_area = 0;
    user = 6;
}

class Offset {
    trek = 0;
    site = 0;
    touring_area = 0;
    user = 0;
}
