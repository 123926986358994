import { createAction, props } from '@ngrx/store';

export const syncQuery = createAction(
    '[Search] Sync Query',
    props<{ query: string }>()
);
export const updateFilter = createAction(
    '[Search] Update Filter',
    props<{
        id: string;
        filter_type: string;
        value: boolean | [number, number];
    }>()
);
export const updateSelectedTab = createAction(
    '[Search] Update Selected Tab',
    props<{ tab_num: number }>()
);
